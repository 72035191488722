import React from "react";

export default function XcashThirdPage() {

    return (

        <div>

            <ol type="A" >
                <li className="TermAndServicesOrderedList Xp"> NFT
                    <ol>
                        <li className="TermAndServicesunderlist Xp">X$WAPP ALLOCATED PER NFT OR NFT PAIR</li>

                    </ol>

                </li>

                <li className="TermAndServicesOrderedList Xp">CCFC only
                    <ol>
                        <li className="TermAndServicesunderlist Xp">4000 tokens</li>

                    </ol>

                </li>

                <li className="TermAndServicesOrderedList Xp">NFT
                    <ol>
                        <li className="TermAndServicesunderlist Xp">X$WAPP ALLOCATED PER NFT OR NFT PAIR</li>

                    </ol>

                </li>

                <li className="TermAndServicesOrderedList Xp">Cashback Crypto Fanimatics Club (token IDs do not need to match)
                    <ol>
                        <li className="TermAndServicesunderlist Xp">5000 tokens</li>

                    </ol>

                </li>
                <li className="TermAndServicesOrderedList Xp">Climatic Change Fanimatics Club (token IDs do not need to match)
                    <ol>
                        <li className="TermAndServicesunderlist Xp">3000 tokens</li>

                    </ol>

                </li>

            </ol>
            <p className="Xp"> There is no distinction between CCFC types for the claim. <br />
                IDs do not need to match to claim, nor does having the original pairing entitle any extra tokens. <br />
                This is a one-time claim that will identify all eligible NFTs in your wallet, in the order that they appear. There will not be an option to deselect  <br /> an eligible NFT if it is present in your wallet. If you wish to claim for one of your NFTs but not another, you will need to move them to separate wallets. <br />
                A CCFC that has being claimed its tokens cannot be used later to claim tokens for an accompanying Airdrop. All paired NFTs must be claimed together at the same time. <br />
                Unused/unsold CCFC and NFT, do not qualify for token airdrop claim. <br />
                The airdrop claim tokens associated with any NFT can only be claimed once. To check whether certain X$WAPP HOLDERS, has claimed its tokens, use the below checker. <br />
                There will be Binance network gas fees incurred to claim X$WApp.</p>



        </div>


    );
}
import React from "react";

export default function XcashFourthPage() {

    return (

        <div>
            <h4 className="Xp">HOW IT WORKS</h4>

            <p className="Xp"> 15% of the total X$WApp token supply allocated to X$WAPP holders for claim will be transferred to a smart contract. Ownership of the contract will be transferred to a multi-sig wallet, which will be responsible for:.</p>
            <p className="Xp">
                As the open-source protocol layer of the ecosystem, X$WApp serves several purposes:
            </p>
            <ol>
                <li className="TermAndServicesunderlist Xp">initiating the X$WAPP claim period</li>
                <li className="TermAndServicesunderlist Xp">ending the claim period 90 days (about 3 months) after launch</li>
                <li className="TermAndServicesunderlist Xp">taking ownership of all unclaimed X$WApp tokens at the end of the claim period</li>
                <li className="TermAndServicesunderlist Xp">transferring these unclaimed tokens to the Preferred Custody wallets that constitute the Ecosystem Fund.</li>

            </ol>
            <p className="Xp">
                The airdrop claim tokens associated with any NFT can only be claimed once. To check whether a certain X$WAPP, (or X$WAPP or with a companion) has claimed its tokens, use the NFT checker:
            </p>

            <h4 className="Xp">AIR 4 LIFE FOUNDATION Inc.</h4>
            <p className="Xp">
                The A4LF Foundation was gifted one batch of X$WAPP, BITCA$H AND 2 set of CCFC NFT’s by CIS Labs Dev Team, the creators of X$Wapp and BitCa$h. You can see it here (link). CIS Labs has conveyed all rights and privileges to these smart contracts and its underlying artwork to the Air 4 Life Foundation. The X$WApp DAO can decide how this intellectual property is used.
            </p>






        </div>


    );
}
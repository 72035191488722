import React from "react";

export default function XcashFifthPage() {

    return (

        <div>
            <h4 className="Xp">FAQ</h4>
            <h4 className="Xp">WHO/WHAT ARE THE DIFFERENT ENTITIES AND NAMES, AND HOW DO THEY RELATE TO EACH OTHER?</h4>

            <ol>
                <li className="TermAndServicesunderlist Xp">Cyber Infrastructure Labs - CIS Labs is the largest technological company on the sub-continent and will be Best known for the creation of the BitCa$h. The development team will be a community member in the X$WApp DAO. The Dev Team will seek the adoption of BitCa$h as the primary Global Digital Cashback Reward Currency across new projects.</li>
                <li className="TermAndServicesunderlist Xp">X Ca$h World APP - The Fanimatics is a collection of digital art crafted into NFTs where the token itself doubles as a membership to DAO community to aid humanity.</li>
                <li className="TermAndServicesunderlist Xp">A4LF Foundation - The Air 4 Life Foundation is the steward of X$Wapp token, and BitCa$h. This legally registered 501 public charity entity that exists to administer the decisions of the X$WApp DAO.</li>
                <li className="TermAndServicesunderlist Xp">X Ca$h World App DAO - A decentralized governance organization that will make decisions regarding Ecosystem Fund allocations, governance rules, projects, partnerships, and more for BitCa$h A Global Digital Cashback Currency. X$WApp DAO membership is open to all X$WApp holders.</li>
                <li className="TermAndServicesunderlist Xp">X Cash World App - The symbol for X$WApp token.</li>

            </ol>

            <h4 className="Xp">DOES THE FOUNDATION CONTROL X$WAPP AND/OR THE X$WAPP DAO? IF NOT, WHAT ROLE DO THEY PLAY?</h4>
            <p className="Xp"> The Foundation does not control X$WApp or the X$WApp DAO. The Foundation consists of an administrative Board, which exists solely to oversee the decisions of the X$WApp DAO, as well as a third-party project management team in charge of ensuring X$WApp DAO decisions are implemented.<br />A decentralized autonomous organization (DAO) is the best way to give every member of the community a vote on important decisions whether it is a technical upgrade or a decision to fund a new idea. However, the reality is that today a DAO cannot sign a lease, hire people, or make merch or whatever the community decides to do on its own. The Foundation is responsible for the day-to-day administration, bookkeeping, project management, and other tasks that ensure the X$WApp DAO community’s ideas have the support they need to make BitCa$h become a reality.</p>

            <h4 className="Xp">HOW is THE FOUNDATION BOARD to be SELECTED?</h4>
            <p className="Xp">Certain members of the community that have strong operational experience are urged to consult with the Board of Directors on how to best structure the X$WApp DAO to oversee BitCa$h succeed in becoming the first Global Digital Cashback Currency. Several of these members who have express their willingness to join the Board of trustees to oversee the decisions of the community and are committed to upholding and furthering the decentralization of the X$WApp DAO. The initial Board will serve 6 months.</p>





        </div>


    );
}
import React from "react";

export default function XcashSixthPage() {

    return (

        <div>
            <h4 className="Xp">WILL THERE BE A CHANCE FOR OTHER INDIVIDUALS TO JOIN THE BOARD?</h4>
            <p className="Xp">Yes. After the initial 6-month term, DAO members will vote annually to keep existing or appoint new Board members. X$WApp token holders (the DAO members) can also remove or replace a Board member at any time with a majority “In favor” vote.</p>

            <h4 className="Xp">WHAT ROLE DOES CIS LABS PLAY IN ALL OF THIS?</h4>
            <p className="Xp">CIS Labs is a contributor to the Ecosystem and will assist in the creation of products and security protocols for the ecosystem.</p>

            <h4 className="Xp">WHAT WILL THE CHARITABLE DONATION TO THe AIR FOR LIFE FOUNDATION BE USED FOR?</h4>
            <p className="Xp">The donation will help secure an invested endowment for the A4LF, the earnings from which will provide ongoing funding for the programs outline in the mission statement and white paper. These include the long-term visions highlighted in the prospectus. www.air4lifefoundation.org/whitepaper.</p>

            <h4 className="Xp">HOW WERE THE TOKEN ALLOTMENTS FOR X$WAPP, AND Bitca$h NFT HOLDERS CALCULATED?</h4>
            <p className="Xp">The allotment of X$WApp to NFT holders was a ratio based on the average floor price of X$WAPP  from the month prior to launch (02/07/22 to 03/08/22).</p>

            <h4 className="Xp">WHY ONLY THOSE WITH X$WAPP TOKENS ARE THE ONES THAT CAN BUY NFTS PRE ALLOTMENT?</h4>
            <p className="Xp">This is to keep the DAO community actively engaged in performing their duties without fear or fear to any individual or organization which does not have the global population as the main purpose for the discharging their duties effectively. NFT’s, they do not have utility on their own, until first purchased only with a X$WAPP.</p>

        </div>


    );
}
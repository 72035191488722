import React from "react";

export default function BitcashFirstPage() {

    return (

        <div>
            <h4 className="Xp">BitcashFirstPage</h4>

            <p className="Xp"> X-Cash $WApp (X$Wapp)is a BEP-20 governance and utility token used within the Commerja-X, E-Commerce Ecosystem to empower and promote trade within the global decentralized community.</p>
            <p className="Xp">
                As the open-source protocol layer of the ecosystem, X$WApp serves several purposes:
            </p>
            <ol type="A" >
                <li className="TermAndServicesOrderedList Xp"> Governance
                    <ol>
                        <li className="TermAndServicesunderlist Xp">X Cash World App(X$Wapp) is the ecosystem’s native currency and governance token, allowing X$WApp holders to participate in Commerja-X DAO, which grants voting rights in the Air 4 life foundation.</li>

                    </ol>

                </li>

                <li className="TermAndServicesOrderedList Xp">Unification of Spend
                    <ol>
                        <li className="TermAndServicesunderlist Xp">X$WApp is the ecosystem’s utility token, giving all its participants a shared and open currency that can be used without centralized intermediaries.</li>

                    </ol>

                </li>

                <li className="TermAndServicesOrderedList Xp">Access
                    <ol>
                        <li className="TermAndServicesunderlist Xp">X$WApp provides access to certain parts of the ecosystem that are otherwise unavailable, such as exclusive merchandise, events, and services</li>

                    </ol>

                </li>

                <li className="TermAndServicesOrderedList Xp">Incentivization
                    <ol>
                        <li className="TermAndServicesunderlist Xp">X$WApp is a passport for the global community to participate in an ecosystem design for their empowerment while incorporating services, and other projects to protect the environment.</li>

                    </ol>

                </li>

            </ol>

            <h4 className="Xp">X$WAPP ALLOCATION</h4>
            <p className="Xp"> The total supply of X$WApp is dynamical fixed at 8 billion tokens to the world population figures. A minting capability is exposed through the contract interface; thus, the total supply will automatically be proportional to the global population for perpetuity. Similarly, the contract interface will expose a token burning capability, so the total supply will never decrease after the initial 800 billion was reduced to correspond to the current global population updated once daily at 0000hrs EST,<br />In line with the standard practice, a portion of the tokens for contributors to the project will be initially locked. Locked tokens will be unlocked over a period of 48 months (about 4 years), in accordance with the predetermined unlock schedule in the chart below, starting on launch day, April 27th, 2022. <br />X$WApp will be distributed among main groups, as detailed in the chart below. Wallets holding tokens allocated to the DAO treasury and resources will be able to be viewed here (link).</p>

            <h4 className="Xp">62% <br />ECOSYSTEM FUND</h4>
            <p className="Xp"> X$WAPP NFT holders and treasury/resources 496,000;000,000, tokens to X$WAPP All unlocked for claim at launch <br />470,000,000,000 tokens to DAO treasury and resources 117,500,000 unlocked at launch, then 7,343,750 unlocked per month for 48 months (about 4 years)</p>


        </div>


    );
}
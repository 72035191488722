import React from "react";

export default function XcashSecondPage() {

    return (
        <div>

            <h4 className="Xp">20% <br />AIR 4 LIFE FOUNDATION + CHARITY</h4>
            <p className="Xp"> The company behind X$WAPP and continuing contributor <br />160,000,000,000 tokens to AIR 4 LIFE FOUNDATION<br />Initial lock-up 12 months, then 4,166,666.67 unlocked per month for 36 months (about 3 years)<br /> 100,000,000 tokens or equal value donated to Climate Change Funding<br />
                Initial lock-up 12 months, then 277,777.78 tokens unlocked per month for 36 months (about 3 years)<br /> 100,000,000, tokens or equal value for Illiteracy Funding</p>

            <h4 className="Xp">10% <br />LAUNCH CONTRIBUTORS</h4>
            <p className="Xp">The companies and people that helped make this project a reality
                80,000,000.000 tokens to launch contributors. Lockup schedules vary by contributor but fall into one of the following:</p>
            <ol>
                <li className="Xp">10,000,000 tokens upfront, 25,000,000 after 6 months, 25,000,000 after 12 months, and 25,000,000 after 18 months (about 1 and a half years)</li>
                <li className="TermAndServicesunderlist Xp"> Initial lock-up for 12 months, then 757,575.76 unlocked per month for 33 months (about 3 years)</li>
                <li className="TermAndServicesunderlist Xp"> Initial lock-up for 12 months, then 833,333.33 unlocked per month for 36 months (about 3 years)</li>

            </ol>

            <h4 className="Xp">8% <br />X$WAPP FOUNDERS</h4>
            <p className="Xp">he ones who put the cash in X$WApp, the four founders of X$WAPP 64,000,000,000 tokens to founders of Commerja-X Corporation Initial lock-up for 12 months, then 2,222,222.22 unlocked per month for 36 months (about 3 years)</p>



            <h4 className="Xp">X$WAPP CLAIMING, FOR X$WAPP AND CCFC HOLDERS </h4>

            <p className="Xp">Tokens will be allocated to X$WAPP members via the ICO page starting on April 27, 2022, at 8:30AM ET.</p>
            <p className="Xp">
                To ensure a fair launch of X$WApp to X$WAPP and CCFC NFT holders, the allocation is informed by the difference in floor price between each collection of NFTs, the month prior to the token claim launch (to 03/31/22). Each Cashback Crypto Fanimatics NFT that has a companion Climate Change Fanimatics  Club NFT will be able to claim the amount of X$WApp specified below.
            </p>

        </div>
    );
}